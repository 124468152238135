import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../../components/form/PrimaryButton";
import { ReactComponent as Lock } from "../../assets/lock-icon.svg";
import { ReactComponent as Envelope } from "../../assets/envelope-icon.svg";
import TextField from "../../components/form/TextField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import ModalContext from "../../contexts/ModalContext";
import { useNavigate } from "react-router-dom"
const AdminLogin = () => {
    // modal context
    const {
        setAlertProps,
        setIsLoginModalOpen,
    } = useContext(ModalContext);

    const [isFormValid, setIsFormValid] = useState(false);
    const [inputValues, setInputValues] = useState({
        email: "",
        password: "",
    });

    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (inputValues.email && inputValues.password) {
            setIsFormValid(false);
        } else {
            setIsFormValid(true);
        }
    }, [inputValues]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        login();
    };

    // function sign-in existing user with email and password
    const { mutate: login, isLoading: loadingEmailAuth } = useMutation(
        () => {
            const payload = {
                email: inputValues.email,
                password: inputValues.password,
            };
            return axios.post(
                `${process.env.REACT_APP_BASE_URI}/auth/signin`,
                payload
            );
        },
        {
            onSuccess: (response) => {
                const userData = {
                    username: response?.data?.user_data?.username,
                    photo: response?.data?.user_data?.profile_photo,
                };
                sessionStorage.setItem("user_data", JSON.stringify(userData));
                sessionStorage.setItem("access_token", response?.data?.accessToken);
                sessionStorage.setItem("refresh_token", response?.data?.refresh_token);

                setAlertProps({
                    type: "success",
                    title: "Success",
                    subtitle: response?.data?.message,
                });
                setTimeout(() => {
                    setAlertProps({
                        type: "",
                        title: "",
                        subtitle: null,
                    });
                }, 5000);
                navigate("/admin")
                setIsLoginModalOpen(false);
                // alert(response?.data?.message);
            },
            onError: (error) => {
                setErrorMessage(error?.response?.data?.message || "Error");

                setAlertProps({
                    type: "fail",
                    title: "Error",
                    subtitle: error?.response?.data?.message,
                });
                setTimeout(() => {
                    setAlertProps({
                        type: "",
                        title: "",
                        subtitle: null,
                    });
                }, 5000);
            },
            onMutate: () => { },
            onSettled: () => { },
        }
    );
    return (
        <div className="w-full grid place-items-center h-screen">
            <div className="bg-[#F2F2F2] overflow-y-hidden h-[600px]  relative px-5 pt-7 pb-4 md:rounded-[36px] md:w-[650px] w-full">

                <h1 className="text-2xl text-center text-[#282B30] font-bold mt-4 mb-1 md:text-3xl">
                    Login as Admin
                </h1>

                <p

                    className="text-[#282B30] text-xs text-center mt-1 mb-12 cursor-pointer"
                >
                    Holla Admin! How u dey?
                </p>

                <form className="md:w-[400px] mt-5 mx-auto">
                    {errorMessage !== "" && (
                        <div className="mb-9 rounded-full w-full p-3">
                            <p className="text-center text-red-500 font-semibold">
                                {errorMessage}
                            </p>
                        </div>
                    )}
                    <div className="w-full h-14 mb-9">
                        <TextField
                            name="email"
                            type="text"
                            placeholder="Enter email address"
                            handleInputChange={handleInputChange}
                            value={inputValues.email}
                            icon={<Envelope />}
                        />
                    </div>
                    <div className="w-full h-14 mb-2">
                        <TextField
                            name="password"
                            type="password"
                            placeholder="Enter password"
                            handleInputChange={handleInputChange}
                            value={inputValues.password}
                            icon={<Lock />}
                        />
                    </div>


                    <div className="w-full h-14 mt-10">
                        <PrimaryButton
                            handle={handleSubmit}
                            isButtonDisabled={!!isFormValid}
                            isButtonLoading={loadingEmailAuth}
                        >
                            Log in
                        </PrimaryButton>
                    </div>
                </form>

            </div>
        </div>
    );
};

export default AdminLogin;
