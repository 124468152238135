import { createContext, useEffect, useState } from "react";
import ToasterAlert from "../components/alerts/Toaster";
import { BlurModal, OpaqueModal, SlideModal } from "../Layout/Modal";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import Login from "../pages/authentication/Login";
import RecoverPassword from "../pages/authentication/RecoverPassword";
import Register from "../pages/authentication/Register";
import VerifyPassword from "../pages/authentication/VerifyPassword";
import FeedBackModal from "../components/FeedBackModal";
import PreOrder from "../pages/authentication/PreOrder";
import VerifyPayment from "../pages/checkout/VerifyPayment";

const ModalContext = createContext();
export const ModalProvider = ({ children }) => {
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [isRecoverPasswordOpen, setIsRecoverPasswordOpen] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [isPreloaderOpen, setIsPreloaderOpen] = useState(false);
  const [isPreOrderOpen, setIsPreOrderOpen] = useState(false);
  const [isVerifyPaymentModal, setIsVerifyPaymentModal] = useState(false);

  const [alertProps, setAlertProps] = useState({
    type: "",
    title: "",
    subtitle: "",
  });
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false)
  const [isFeedBackOpen, setIsFeedBackOpen] = useState(false)

  //   function to scroll up
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  useEffect(() => {
    isRegisterModalOpen && scrollUp();
    isLoginModalOpen && scrollUp();
  }, [isRegisterModalOpen, isLoginModalOpen]);

  return (
    <ModalContext.Provider
      value={{
        isRegisterModalOpen,
        isLoginModalOpen,
        isAlertModalOpen,
        alertProps,
        isPreloaderOpen,
        isForgotPasswordOpen,
        isRecoverPasswordOpen,
        isVerifyModalOpen,
        isFeedBackOpen,
        isPreOrderOpen,
        isVerifyPaymentModal,
        setIsRecoverPasswordOpen,
        setIsForgotPasswordOpen,
        setIsPreloaderOpen,
        setAlertProps,
        setIsRegisterModalOpen,
        setIsLoginModalOpen,
        setIsAlertModalOpen,
        setIsVerifyModalOpen,
        setIsFeedBackOpen,
        setIsPreOrderOpen,
        setIsVerifyPaymentModal
      }}
    >
      {/* register modal */}
      <BlurModal
        isModalOpen={isRegisterModalOpen}
        onClose={() => {
          setIsRegisterModalOpen(false);
          setIsLoginModalOpen(false);
        }}
      >
        <Register />
      </BlurModal>
      <BlurModal
        isModalOpen={isFeedBackOpen}
        onClose={() => {
          setIsFeedBackOpen(false);
        }}
      >
        <FeedBackModal />
      </BlurModal>

      {/* forgot password modal */}
      <BlurModal
        isModalOpen={isForgotPasswordOpen}
        onClose={() => {
          setIsForgotPasswordOpen(false);
        }}
      >
        <ForgotPassword />
      </BlurModal>

      {/* forgot password modal */}
      <BlurModal
        isModalOpen={isRecoverPasswordOpen}
        onClose={() => {
          setIsRecoverPasswordOpen(false);
        }}
      >
        <RecoverPassword />
      </BlurModal>

      {/* login modal */}

      <BlurModal
        isModalOpen={isLoginModalOpen}
        onClose={() => {
          setIsRegisterModalOpen(false);
          setIsLoginModalOpen(false);
        }}
      >
        <Login />
      </BlurModal>

      <BlurModal
        isModalOpen={isVerifyModalOpen}
        onClose={() => {
          setIsVerifyModalOpen(false);
        }}>
        <VerifyPassword />

      </BlurModal>
      {/* verify paymemt */}
      <SlideModal
        isModalOpen={isVerifyPaymentModal}
        onClose={() => {
          setIsVerifyPaymentModal(false)
        }}
      >
        <VerifyPayment />
      </SlideModal>

      {/* pre ordeer */}
      <BlurModal
        isModalOpen={isPreOrderOpen}
        onClose={() => {
          setIsPreOrderOpen(false);
        }}
      >
        <PreOrder />
      </BlurModal>
      {/* preloader */}
      <OpaqueModal isModalOpen={isPreloaderOpen}>
        <svg
          className=" animate-spin"
          width="40"
          height="40"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
            stroke="#FE724E"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </OpaqueModal>

      <ToasterAlert
        type={alertProps.type}
        title={alertProps.title}
        subtitle={alertProps.subtitle}
      />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
