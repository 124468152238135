import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyDqImY0KtXpz7b7wBMD92ryMkeSdLAoEVs",
  authDomain: "tasteclan-87c61.firebaseapp.com",
  projectId: "tasteclan-87c61",
  storageBucket: "tasteclan-87c61.appspot.com",
  messagingSenderId: "984982242498",
  appId: "1:984982242498:web:8017a40e3e7fdb3aa9275b",
  measurementId: "G-MDXQNC956E",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const app = initializeApp(firebaseConfig);

const auth = getAuth();
export const storage = getStorage(app)

// firebase auth providers
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };

export default getFirestore();
