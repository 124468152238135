import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";

const AuthenticatedRoutes = () => {
  // auth context
  const { accessToken } = useContext(AuthContext);
  // const accessToken = ""

  if (accessToken) {
    return <Outlet />;
  }
  //return <Outlet />;

  return (
    <>
      <Navigate to="/very-secretive-admin-login-for-tasteclan-webite" />
    </>
  );
};

export default AuthenticatedRoutes;
