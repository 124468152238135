import React from 'react';
import PropTypes from 'prop-types';

export const RollingLoader = ({ color = '#000', size = 'md' }) => {
    const sizes = {
        sm: 24,
        md: 48,
        lg: 72,
    };

    const dimension = sizes[size] || sizes.md;

    return (
        <svg
            width={dimension}
            height={dimension}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke={color}
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    keyTimes="0;1"
                    values="0 50 50;360 50 50"
                ></animateTransform>
            </circle>
        </svg>
    );
};

RollingLoader.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

