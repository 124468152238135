import { useMutation } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import ModalContext from "./ModalContext";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Modal context
  const { setAlertProps } = useContext(ModalContext);

  const [isFirstMounted, setIsFirstMounted] = useState(true);

  //  Get current access token from browser storage
  function getAccessToken() {
    return sessionStorage.getItem("access_token");
  }

  //  Get current refresh token from browser storage
  function getRefreshToken() {
    return sessionStorage.getItem("refresh_token");
  }
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  //  Time interval to get new access token after 30minutes
  const TOKEN_EXPIRATION_TIME = 30 * 60 * 1000;

  // function to get new access token via refresh token
  const { mutate: updateAccessToken } = useMutation(
    () => {
      const payload = {
        token: refreshToken,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URI}/auth/refresh-token`,
        payload
      );
    },
    {
      onSuccess: (response) => {
        sessionStorage.setItem("access_token", response?.data?.accessToken);
        sessionStorage.setItem("refresh_token", response?.data?.refresh_token);

        if (isFirstMounted) {
          setIsFirstMounted(false);
        }
      },
      onError: (error) => {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      },
      onMutate: () => { },
      onSettled: () => { },
    }
  );

  // Get new access token at intervals
  useEffect(() => {
    if (refreshToken) {
      if (isFirstMounted) {
        updateAccessToken();
      }
      const interval = setInterval(() => {
        updateAccessToken();
      }, TOKEN_EXPIRATION_TIME);
      return () => clearInterval(interval);
    }
  }, [
    accessToken,
    isFirstMounted,
    refreshToken,
    updateAccessToken,
    TOKEN_EXPIRATION_TIME,
  ]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
