import React, { useContext, useState } from "react";
import ModalContext from "../../contexts/ModalContext";
import CopyIcon from "../../components/ui/clipboard";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import useUploadFile from "./hooks/useUploadFile";
import { RollingLoader } from "../../components/alerts/loader";
import { useNavigate } from "react-router-dom";

const { REACT_APP_BASE_URI } = process.env;
const uploadReceiptUrl = `${REACT_APP_BASE_URI}/checkout/upload_payment_proof`;

const VerifyPayment = () => {
  const { setAlertProps, setIsVerifyPaymentModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const deliveryDetails = JSON.parse(
    sessionStorage.getItem("delivery_details")
  );

  const accountNumber = "8302045962";

  let order_id = sessionStorage.getItem("order_id");

  const amountTobePaid =
    JSON.parse(sessionStorage.getItem("amountToBePaid")) ?? 0;

  const [selectedReceipt, setSelectedReceipt] = useState({
    name: "",
    file: null,
  });
  const { uploadImage, loading, imageUrl } = useUploadFile();
  const { mutate, isLoading: isReceipting } = useMutation(
    (payload) => axios.post(uploadReceiptUrl, payload, {}),
    {
      onSuccess: (res) => {
        setIsVerifyPaymentModal(false);
        navigate("/checkout/order_notification");
        setAlertProps({
          type: "success",
          title: "Success",
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 3000);
        sessionStorage.clear();
      },
      onError: (err) => {
        // console.log(err)
      },
    }
  );

  const handleUploadFile = (e) => {
    if (e) e.preventDefault();

    const file = e.target.files[0];
    uploadImage(file);
    setSelectedReceipt({ ...selectedReceipt, file, name: file.name });
  };

  const handleSubmitReceipt = (e) => {
    if (e) e.preventDefault();
    if (selectedReceipt.file === null) {
      setAlertProps({
        type: "fail",
        title: "Error",
        subtitle: "Upload your Receipt",
      });
      setTimeout(() => {
        setAlertProps({
          type: "",
          title: "",
          subtitle: null,
        });
      }, 3000);
      return;
    }

    try {
      const payload = {
        email: deliveryDetails?.email,
        image_url: imageUrl,
        order_id,
      };

      mutate(payload); // uploading proof of payment
    } catch (error) {
      setAlertProps({
        type: "fail",
        title: "Error",
        subtitle: "error?.response?.data?.message",
      });
      setTimeout(() => {
        setAlertProps({
          type: "",
          title: "",
          subtitle: null,
        });
      }, 5000);
    }
  };

  return (
    <div className="bg-[#F2F2F2] pb-0 md:pb-6">
      <main className="h-fit w-full pt-6 grid place-items-start md:place-items-center ">
        <div className="px-6 w-full">
          <h2 className="text-[#A8ABB2]  text-start font-semibold text-[16px] md:text-2xl">
            Confirm Payment
          </h2>
          <div className="text-dark">
            <p className="font-semibold mb-2">
              Send Payment to the account details
            </p>
            <p>
              Bank Name: <span>Moniepoint MFB</span>
            </p>
            <p>
              Account Name: <span>TASTE CLAN</span>
            </p>
            <p className="items-center flex">
              {"Account Number: "}
              <span className="mx-3">{accountNumber}</span>
              <CopyIcon text={accountNumber} />
            </p>

            <p>Amount to be Paid: {amountTobePaid.toLocaleString()}</p>
          </div>
          <div className="border border-primary border-dashed mt-4 grid place-items-center rounded-md p-3 w-full md:w-[500px] h-[150px]">
            <label
              className="hover:bg-secondary focus:bg-secondary text-dark p-1 rounded-md hover:shadow-sm"
              htmlFor="upload-file"
            >
              {selectedReceipt.name === ""
                ? "Upload Payment Receipt"
                : "Replace Upload"}
            </label>
            <input
              onChange={handleUploadFile}
              id="upload-file"
              type="file"
              className="hidden"
            />
            <p
              className={`${selectedReceipt.name === "" ? "hidden" : "block"}`}
            >
              {selectedReceipt.name}
            </p>
            <span>
              {loading ? <RollingLoader size="md" color="#282B30" /> : null}
            </span>
          </div>
        </div>

        <button
          onClick={handleSubmitReceipt}
          disabled={isReceipting}
          className="w-full hidden md:block h-[56px] md:w-[500px] md:h-[72px] bg-[#FE724E] mt-4 md:rounded-[1000px] text-white font-bold"
        >
          {isReceipting ? (
            <svg
              className="mx-auto animate-spin"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
                stroke="#F9F9F9"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            "Confirm Payment"
          )}
        </button>
      </main>

      <button
        onClick={handleSubmitReceipt}
        disabled={isReceipting}
        className={`${
          isReceipting ? "bg-gray-400 text-dark" : "bg-primary text-white"
        } md:hidden block w-full mt-10  py-4 font-semibold`}
      >
        {isReceipting ? (
          <svg
            className="mx-auto animate-spin"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
              stroke="#F9F9F9"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          "Confirm Payment"
        )}
      </button>
    </div>
  );
};

export default VerifyPayment;
