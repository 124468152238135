
import ModalContext from "../../contexts/ModalContext"
import { useContext } from "react"
const CopyIcon = ({ width = 16, height = 16, text }) => {
    const { setAlertProps } = useContext(ModalContext);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(text).then(() => {
            setAlertProps({
                type: "success",
                title: "Copied to Clipboard",
                subtitle: "",
            });
            setTimeout(() => {
                setAlertProps({
                    type: "",
                    title: "",
                    subtitle: null,
                });
            }, 5000);
        }).catch(err => {
            setAlertProps({
                type: "fail",
                title: "failed to copy",
                subtitle: "",
            });
            setTimeout(() => {
                setAlertProps({
                    type: "",
                    title: "",
                    subtitle: null,
                });
            }, 5000);
        });
    };

    return (
        <button onClick={handleCopyToClipboard}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-copy"
            >
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
            </svg>
        </button>

    )
};

export default CopyIcon;