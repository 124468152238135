import React from "react";
import PropTypes from "prop-types";

// custom checkbox component
const CustomCheckBox = ({
    name,
    isChecked = () => { },
    isNotChecked = () => { },
    checked = false,
}) => {
    return (
        <label>
            <input
                type="checkbox"
                name={name}
                checked={checked}
                className="peer hidden "
                onChange={(e) => (e.target.checked ? isChecked() : isNotChecked())}
            />

            {/* displays when checkbox is unchecked */}
            <span className="w-6 h-6 inline-block rounded-full cursor-pointer bg-[#ffffff] peer-checked:hidden peer-focus:border-4 peer-focus:border-green-500"></span>

            {/* displays when checkbox is checked */}
            <span className="w-6 h-6 rounded-full cursor-pointer bg-primary hover:bg-[#BE391C] relative hidden peer-checked:inline-block peer-focus:border-4 peer-focus:border-green-500">
                <svg
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.25838 7.24627L1.42259 5.26362C1.09715 4.91215 0.569513 4.91215 0.244077 5.26362C-0.081359 5.61509 -0.081359 6.18494 0.244077 6.53641L2.74406 9.23638C3.09734 9.61792 3.67992 9.5803 3.98858 9.15603L9.8219 1.95607C10.1062 1.56523 10.0434 0.999442 9.68148 0.692351C9.31959 0.38526 8.79571 0.453155 8.51137 0.843998L3.25838 7.24627Z"
                        fill="#F2F2F2"
                    />
                </svg>
            </span>
        </label>
    );
};
CustomCheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.func.isRequired,
    isNotChecked: PropTypes.func.isRequired,
};
export default CustomCheckBox;
