import React from "react";
import Navigation from "../../Layout/Navigation";
import PrimaryButton from "../../components/form/PrimaryButton";
import CompleteOrder from "../../assets/complete_order.svg";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
// import AuthContext from "../../contexts/AuthContext";
// import { useQuery } from "@tanstack/react-query";
// import ModalContext from "../../contexts/ModalContext";

function OrderNotification() {
  // const { REACT_APP_BASE_URI } = process.env;
  // //const { status, tx_ref } = useParams();
  // const { setAlertProps, setIsPreloaderOpen } = useContext(ModalContext);

  // const [authorized, setAuthorized] = useState(false);
  // const [responseText, setResponseText] = useState("");
  // //const [isLoading, setIsLoading] = useState(false)

  // const { search } = useLocation();
  // // console.log('search', search)

  // const method = new URLSearchParams(search);
  // // console.log('abeg work abeg ni o: ', method.get("status"))
  // // console.log('abeg tx_ref abeg ni o: ', method.get("tx_ref"))
  // // console.log('abeg transaction abeg ni o: ', method.get("transaction_id"))

  // const status = method.get("status");
  // const tx_ref = method.get("tx_ref");
  // const transaction_id = method.get("transaction_id");

  // console.log(tx_ref);

  // const orderCompleteUrl = `${REACT_APP_BASE_URI}/checkout/order_notification?status=${status}&tx_ref=${tx_ref}&transaction_id=${transaction_id}`;

  // const { accessToken } = useContext(AuthContext);
  // //const order_id = sessionStorage.getItem("order_id");

  // // navigation
  const navigate = useNavigate();

  // const fetchRestaurants = () => {
  //   return axios.get(orderCompleteUrl, {
  //     headers: { Authorization: `Bearer ${accessToken}` },
  //   });
  // };
  // // Query to fetch list of restuarants
  // const { isLoading } = useQuery(["restaurants-list"], fetchRestaurants, {
  //   onSuccess: () => {
  //     setAuthorized(true);
  //   },
  //   onError: (error) => {
  //     setResponseText(error?.response?.data?.message);
  //     navigate("/");
  //     setAuthorized(false);
  //     setAlertProps({
  //       type: "fail",
  //       title: "Error",
  //       subtitle: error?.response?.data?.message,
  //     });
  //     setTimeout(() => {
  //       setAlertProps({
  //         type: "",
  //         title: "",
  //         subtitle: null,
  //       });
  //     }, 5000);
  //   },
  //   retry: false,
  // });

  return (
    <React.Fragment>
      <Navigation />

      <main className="mt-20 w-full flex flex-col justify-center items-center h-[80vh] overflow-hidden">
        <section className="w-10 mb-11 leading-8">
          <img src={CompleteOrder} alt="order confirmed" />
        </section>
        <div className="leading-8 px-10 mb-10 text-[#7B7B7B]">
          <p className="mb-2 font-semibold text-[26px] md:text-[48px]">
            Your Order is successful!
          </p>
          <p className="text-[15px] md:text-[23px]">
            Your receipt will be veryified, and your order will be with you soon {" "}
          </p>
        </div>
        <div className="w-full md:w-1/5 px-10">
          <PrimaryButton
            handle={() => {
              navigate("/");
            }}

          >
            Home Page
          </PrimaryButton>
        </div>
      </main>
    </React.Fragment>
  );
}

export default OrderNotification;
